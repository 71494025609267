.label {
  .question {
    width: 18px;
    height: 18px;
    fill: #ddd;

    &:hover {
      fill: #f9b115;
    }
  }
}