.video {
    width: 104px;
    height: 104px;
    position: relative;
    background-color: var(--gray20);
    border-radius: 4px;
    margin-bottom: 24px;
    margin-right: 8px;
    object-fit: cover;
    z-index: 1;
}