:root {
  .editor {
    --editor-spacing-unit: 0.6em;
    --editor-ui-component-min-height: 2.3em;
    --editor-spacing-tiny: calc(var(--editor-spacing-unit) * 0.3);
    --editor-spacing-small: calc(var(--editor-spacing-unit) * 0.5);

    --editor-color-text: #333;
    --editor-color-base-border: #ccced1;
    --editor-color-base-background: #fff;

    border-radius: 0;

    .toolbar {
      --editor-color-toolbar-text: var(--editor-color-text);
      --editor-color-toolbar-border: var(--editor-color-base-border);
      --editor-color-toolbar-background: var(--editor-color-base-background);

      color: var(--editor-color-toolbar-text);
      border: 1px solid var(--editor-color-toolbar-border);
      background: var(--editor-color-toolbar-background);

      padding: 0 var(--editor-spacing-small);

      .items {
        flex-wrap: nowrap;
        align-items: center;
        display: flex;
        flex-flow: row wrap;
        flex-grow: 1;

        & > * {
          margin-right: var(--editor-spacing-small);
        }

        .separator {
          display: inline-block;
          align-self: stretch;
          background: var(--editor-color-toolbar-border);
          margin-bottom: var(--editor-spacing-small);
          margin-top: var(--editor-spacing-small);
          min-width: 1px;
          width: 1px;
        }

        button {
          --editor-color-button-default-background: transparent;

          color: var(--editor-color-text);
          -webkit-appearance: none;
          border: 1px solid transparent;
          cursor: default;
          font-size: inherit;
          line-height: 1;
          min-height: var(--editor-ui-component-min-height);
          min-width: var(--editor-ui-component-min-height);
          padding: var(--editor-spacing-tiny);
          text-align: center;
          transition: box-shadow .2s ease-in-out, border .2s ease-in-out;
          vertical-align: middle;
          white-space: nowrap;

          background: var(--editor-color-button-default-background);

          &:hover {
            --editor-color-button-on-hover-color: var(--editor-color-text);
            --editor-color-button-on-hover-background: #dbecff;

            color: var(--editor-color-button-on-hover-color);
            background: var(--editor-color-button-on-hover-background);
          }
        }

        .on {
          --editor-color-button-on-color: #2977ff;
          --editor-color-button-on-background: #f0f7ff;

          color: var(--editor-color-button-on-color);
          background: var(--editor-color-button-on-background);
        }
      }
    }

    .content {
      border: 1px solid var(--editor-color-base-border);
      border-top: 0;
      display: flex;
      flex-direction: row;

      .editor {
        width: 0;
        flex-grow: 1;
      }
    }
  }

  .dark-theme {
    .editor {
      --editor-color-text: #aaa;
      --editor-color-base-border: rgba(255, 255, 255, 0.09);
      --editor-color-base-background: rgba(255, 255, 255, 0.05);

      .toolbar {
        background: rgba(255, 255, 255, 0.09);
        border-color: rgba(255, 255, 255, 0.09);

        .items {
          button {
            &:hover {
              --editor-color-button-on-hover-color: #fff;
              --editor-color-button-on-hover-background: #222;
            }
          }

          .on {
            --editor-color-button-on-color: #fff;
            --editor-color-button-on-background: #2977ff;
          }
        }
      }
    }
  }
}