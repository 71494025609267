.container {

  .items {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 5px;

    .item, .fog {
      border-radius: 0.375rem;
    }

    .item {
      position: relative;
      margin-right: 5px;
      margin-bottom: 5px;
      height: 200px;
      min-width: 200px;

      border: solid 1px var(--ck-color-base-border);
      cursor: pointer;

      .media {
        height: 200px;
        border-radius: 0.375rem;
      }

      .fog {
        display: block;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(0, 0, 0, 0);

        .toolbar {
          display: none;
          margin: 10px;

          .button {
            fill: white;
            width: 24px;
            height: 24px;
            float: right;
            margin-left: 5px;
            opacity: 0.75;

            &:hover {
              opacity: 1;
            }
          }
        }

        &:hover {
          background-color: rgb(0, 0, 0, 0.3);

          .toolbar {
            display: block;
          }
        }
      }
    }

    .upload {
      height: 200px;
      min-width: 200px;
      align-items: center;
      justify-content: center;
      border: solid 1px var(--ck-color-base-border);
      position: relative;

      &[data-uploading=true] {
        .input {
          cursor: default;
        }
      }

      &:not([data-uploading=true]):hover {
        transition: background-color 0.5s ease;
        background-color: var(--cui-secondary);

        .icon {
          color: var(--cui-body-bg);
        }
      }

      .progress {
        width: 80%;
        /*position: absolute;
        bottom: 10px;*/
        .text {
          display: flex;
          justify-content: center;
          margin-bottom: 10px;

        }
      }

      .input {
        top:0;
        bottom:0;
        opacity: 0;
        width: 100px;
        cursor: pointer;
        position: absolute;

        &::file-selector-button {
          width: 0;
          height: 0;
        }
      }
    }
  }
}
