:root {
  .dark-theme {
    --ck-color-text: #aaa;
    --ck-color-base-border: rgba(255, 255, 255, 0.09);
    --ck-color-base-background: rgba(255, 255, 255, 0.05);
    .ck-toolbar {
      background: rgba(255, 255, 255, 0.09);
      border-color: rgba(255, 255, 255, 0.09);
    }
  }
}