.label {
  font-size: .875em;
  font-style: italic;

  &[data-feedback=valid] {
    color: var(--cui-success);
  }

  &[data-feedback=invalid] {
    color: var(--cui-danger);
  }
}