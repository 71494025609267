// Here you can add other styles

:root {
  // Round colors
  --brand-red: #FF3F49;
  --white: white;
  --gray-80: #7A7A7D;
  --gray-50: #A3A3A6;
  --gray-light: #C6C8CB;
  --gray-30: #E1E1E6;
  --gray-20: #F0F0F2;

  --dark-primary-alpha-80: rgba(47, 47, 48, 0.8);
  --dark-primary-alpha-70: rgba(47, 47, 48, 0.7);
  --dark-primary-alpha-60: rgba(47, 47, 48, 0.6);
  --dark-primary-alpha-50: rgba(47, 47, 48, 0.5);
}

/*$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
);*/

/*$primary: #FF3F49;
$secondary: #8733FF;*/

.fill-secondary {
  --cui-text-opacity: 1;
  fill: rgba(var(--cui-secondary-rgb), var(--cui-text-opacity)) !important;
}

.cursor-pointer {
  cursor: pointer;
  .disabled {
    cursor: auto;
  }
}

.popup {
  z-index: var(--cui-popover-zindex);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
}

.sidebar-nav-v-scroll {
  -webkit-overflow-scrolling: touch;

  @media (hover: hover) and (pointer: fine) {
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--cui-sidebar-brand-bg);
      border-radius: 5px;

      &:hover {
        background-color: var(--cui-dark);
      }
    }

    &::-webkit-scrollbar-button {
      display: none;
    }
  }
}

.trancate-text-cell {
  display: -webkit-box;
  -webkit-line-clamp: var(--lines);
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.media {
  vertical-align: middle;
  width: 100%;
  border-radius: var(--cui-card-inner-border-radius);
  cursor: pointer;
}

